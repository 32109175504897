<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF jE mb-4">
			<div class="dF">
				<div>
					<a-input ref="searchInput" v-model="searchQuery" placeholder="Search worksheet..." style="width: 300px">
						<a-icon slot="prefix" type="search" />
					</a-input>
				</div>
			</div>
		</div>
		<a-card style="padding: 0" class="table-card">
			<a-table class="tableStyle" :columns="completedColumns" :data-source="filtered"
				:row-key="(record) => record.transaction.id">
				<div slot="transactionDate" slot-scope="record">
					<span v-if="record.transaction && record.transaction.createdAt">
						{{ formatDate(record.transaction.createdAt) }}
					</span>
				</div>
				<div slot="agent" slot-scope="record">
					{{ record.agent.firstName + " " + record.agent.lastName }}
				</div>
				<div slot="payoutType" slot-scope="record" class="dF fC">
					<div v-for="(p, i) in record.payouts" :key="i">
						<span v-if="p.stage">
							Stage: {{ p.stage }}
						</span>
						<span v-else>
							Date: {{ p.date }}
						</span>
					</div>
				</div>
				<div slot="payoutAmount" slot-scope="record" class="dF fC">
					<div v-for="(p, i) in record.payouts" :key="i">
						${{ p.amount && p.amount.toLocaleString("US") }}
					</div>
				</div>
				<div slot="payoutDate" slot-scope="record" class="dF fC">
					<div v-for="(p, i) in record.payouts" :key="i">
						{{ p.date }}
					</div>
				</div>
				<div slot="actions" slot-scope="record">
					<a-button v-if="record.transaction"
						@click="openTransactionDetails(record.transaction)">Details</a-button>
				</div>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import moment from "moment";
import { formatDate } from "bh-mod";

export default {
	components: {},
	watch: {},
	data() {
		return {
			searchQuery: "",
			completedColumns: [
				{
					title: "Transaction Date",
					key: "transactionDate",
					scopedSlots: { customRender: "transactionDate" },
					sorter: (a, b) =>
						moment(a.transaction.createdAt).format("x") -
						moment(b.transaction.createdAt).format("x"),
				},
				{
					title: "Transaction Id",
					dataIndex: "transaction.id",
					key: "transaction",
					sorter: (a, b) =>
						a.transaction.id < b.transaction.id
							? -1
							: a.transaction.id > b.transaction.id
								? 1
								: 0,
				},
				{
					title: "Worksheet Id",
					dataIndex: "worksheet.readableId",
					key: "worksheet",
					sorter: (a, b) =>
						a.worksheet.readableId < b.worksheet.readableId
							? -1
							: a.worksheet.readableId > b.worksheet.readableId
								? 1
								: 0,
				},
				{
					title: "Unit #",
					dataIndex: "unit.unitNumber",
					key: "unitNumber",
					sorter: (a, b) => a.unit.unitNumber - b.unit.unitNumber,
				},
				{
					title: "Marketing Name",
					dataIndex: "unit.name",
					key: "unitName",
					sorter: (a, b) =>
						a.unit.name < b.unit.name
							? -1
							: a.unit.name > b.unit.name
								? 1
								: 0,
				},
				{
					title: "Realtor",
					key: "agent",
					scopedSlots: { customRender: "agent" },
					sorter: (a, b) => {
						const aa = a.agent.firstName + a.agent.lastName;
						const bb = b.agent.firstName + b.agent.lastName;
						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
				},
				{
					title: "Payout Type",
					key: "payoutType",
					scopedSlots: { customRender: "payoutType" },
				},
				{
					title: "Payout Amount",
					key: "payoutAmount",
					scopedSlots: { customRender: "payoutAmount" },
				},
				{
					title: "Payout Date",
					key: "payoutDate",
					scopedSlots: { customRender: "payoutDate" },
				},
				{
					title: "",
					scopedSlots: { customRender: "actions" },
					key: "actions",
				},
			],
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},
		commissionPayouts() {
			return this.$store.state.reports.commissionPayouts;
		},
		filtered() {
			let result = this.commissionPayouts;
			if (this.searchQuery) {
				let query = this.searchQuery.toLowerCase().trim();
				result = result.filter((item) => {
					return (
						item.worksheet.readableId.toLowerCase().includes(query) ||
						item.agent.firstName.toLowerCase().includes(query) ||
						item.agent.lastName.toLowerCase().includes(query) ||
						item.unit.name.toLowerCase().includes(query) ||
						item.unit.unitNumber.toString().toLowerCase().includes(query)
					);
				});
			}
			return result;
		},
	},
	methods: {
		formatDate,

		trim(str, len) {
			if (str.length > len) {
				return str.substring(0, len) + "...";
			} else {
				return str;
			}
		},

		openTransactionDetails(transaction) {
			window.open(`https://transactions.bildhive.${this.$tld}/transaction/${transaction.id}`, '_blank');
		}
	},
	created() {
		this.$store.dispatch("GET_COMMISSION_PAYOUTS");
	},
};
</script>

<style scoped>
</style>
